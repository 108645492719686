import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-335cdb63"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "ai-digital-collocation"
};
import AiDigitalCollocationBanner from "@/views/other_module/ai-digital-collocation-module/ai-digital-collocation-banner.vue";
import AiDigitalContent from "@/views/other_module/ai-digital-collocation-module/ai-digital-content.vue";
export default {
  __name: 'ai-digital-collocation',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(AiDigitalCollocationBanner), _createVNode(AiDigitalContent)]);
    };
  }
};