import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0c01fa5d"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "ai-digital-content"
};
var _hoisted_2 = {
  class: "ai-digital-introduction"
};
var _hoisted_3 = {
  class: "introduction-desc flex-center"
};
var _hoisted_4 = {
  class: "introduction-desc flex-center"
};
export default {
  __name: 'ai-digital-content',
  setup: function setup(__props) {
    var aiDigitalIntroductionList = [{
      id: 0,
      desc: window.$t("AICollocation.analysis"),
      introductionImg: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/ai-module/introductionImg1.png"
    }, {
      id: 1,
      desc: window.$t("AICollocation.digitalPerson"),
      introductionImg: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/ai-module/introductionImg2.png"
    }, {
      id: 1,
      desc: window.$t("AICollocation.intelligentAI"),
      introductionImg: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/ai-module/introductionImg3.png"
    }, {
      id: 1,
      desc: window.$t("AICollocation.outfitMatching"),
      introductionImg: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/ai-module/introductionImg4.png"
    }];
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(aiDigitalIntroductionList, function (item, index) {
        return _createElementVNode("div", {
          key: item.id,
          class: "ai-introduction-item"
        }, [index % 2 === 0 ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createElementVNode("div", {
          class: "introduction-img",
          style: _normalizeStyle({
            background: "url(".concat(item.introductionImg, ") no-repeat")
          })
        }, null, 4), _createElementVNode("div", _hoisted_3, _toDisplayString(item.desc), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.desc), 1), _createElementVNode("div", {
          class: "introduction-img bg-position",
          style: _normalizeStyle({
            background: "url(".concat(item.introductionImg, ") no-repeat")
          })
        }, null, 4)], 64))]);
      }), 64))])]);
    };
  }
};